/* FAQ header styling */
.faq-header {
    color: #ac834f; /* Header color */
    font-size: 3rem; /* Default font size for larger screens */
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 700;
}

/* Media query for screens with max width of 678px */
@media (max-width: 678px) {
    .faq-header {
        font-size: 2.5rem; /* Reduced font size for smaller screens */
    }
    .my-5f {
        padding: 15px;
    }
}

/* Custom Styles for the FAQs accordion */
.accordion-item {
    margin-bottom: 1.2rem; /* Add gap between questions */

    border-radius: 10px; /* Apply rounded corners */
    padding: 3px;
}

/* Button styling */
.accordion-button {
    border: none; /* Remove default border for the button */
    border-radius: 10px; /* Ensure the button doesn't override parent border */
    background-color: #dedede; /* Background color for collapsed header */
    font-size: 1.2rem;
    padding: 1rem; /* Adjust padding to ensure button fits well */
    border: #ac834f 3px solid;
}

/* Change button background color when expanded */
.accordion-button:not(.collapsed) {
    background-color: #f1f1f1; /* Background color when expanded */
    color: black; /* Text color when expanded */
}

/* Accordion body styling */
.accordion-body {
    padding: 1rem;
    background-color: #fff; /* Body background color */
    /* Remove border-top to keep the border of the accordion-item intact */
}

/* Accordion button focus styling */
.accordion-button:focus {
    box-shadow: none; /* Remove focus outline */
}
