body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.app-background {
  background: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQt8m8SXXZFKUUXuqaLSnOzxPkIUBocA0ioBA&s') no-repeat center center fixed; /* Adjust path */
  background-size: cover; /* Cover the whole area */
  min-height: 100vh; /* Ensure it covers the full viewport height */
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  color: white; /* Default text color */
  position: relative; /* Position relative for children elements */
  z-index: 0; /* Set lower z-index for the background */
}