.gallery-section {
    position: relative;
    overflow: hidden;
  }
  
  .background-image {
    z-index: 0; /* Keep it behind other content */
  }
  
  .gallery-heading {
    text-align: center;
    font-size: 3rem; /* Default size for larger screens */
    margin-bottom: 100px; /* Default margin */
    font-weight: 700;
  }
  
  @media (max-width: 600px) {
    .gallery-heading {
      font-size: 2.5rem; /* Smaller size for mobile screens */
      margin-bottom: 50px; /* Smaller margin for mobile */
    }
  }
  
  @media (max-width: 1100px) {
    .gallery-heading {
      font-size: 3rem; /* Adjust for tablets */
    }
  }
  
  /* Carousel styles */
  .carousel-indicators {
    bottom: -30px; /* Position below the card */
  }
  
  .slide {
    padding: 0 10px; /* Margin between slides */
  }
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  /* Override styles for Slick carousel arrows */
  .slick-prev:before,
  .slick-next:before {
    content: ""; /* Clear the default content */
    display: inline-block;
    width: 48px; /* Adjust width as needed */
    height: 48px; /* Adjust height as needed */
  }
  
  .slick-prev:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" /></svg>'); 
    margin-left: -25px !important;
  }
  
  .slick-next:before {
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" /></svg>'); 
  }
  
  /* Responsive styles for Slick carousel */
  @media (max-width: 768px) {
    .custom-card {
      width: 100%;
    }
    .slick-prev:before {
        margin-left: -10px !important;
      }
      .slick-next:before{
        margin-left: -15px !important;
      }
  }
  
  @media (max-width: 400px) {
    .custom-card {
      margin-left: 17px !important;
    }
  }
  
  @media (min-width: 401px) and (max-width: 700px) {
    .custom-card {
      margin-left: 20px !important;
    }
  }
  
  /* Adjust Slick arrows position */
  @media (min-width: 601px) and (max-width: 1050px) {
    .slick-next {
      right: 0.2% !important;
    }
    .slick-prev:before {
        margin-left: -10px !important;
      }
      .slick-next:before{
        margin-left: 15px !important;
      }
  }
  
  @media (min-width: 1051px) and (max-width: 1200px) {
    .slick-next {
      right: -1% !important;
    }
    .slick-prev {
      left: -1% !important;
    }
  }
  