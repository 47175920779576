.custom-navbar {
    width: 100%;
  }
  
  .custom-navbar .container-fluid {
    max-width: 85%;
  }
  
  /* Increase font size and add spacing between navbar links */
  .custom-nav .nav-link {
    font-size: 18px; /* Increased font size */
    margin-right: 20px; /* Increased spacing between nav items */
    font-weight: 500;
  }
  
  .btn-e-magazine {
    margin-left: auto; /* Keep the button on the right */
    font-size: 16px; /* Optional: increase button font size */
    background-color: #ac834f;
    padding: 10px 20px;
    font-weight: 500;
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index:-1;
    
  }
  @media (max-width: 768px) and (min-width: 250px){
    .custom-navbar .container-fluid {
        max-width: 90%;
      }
      .navbar-logo {
        width: 90px;
        height: 40px;
      } 
      
  } 
  @media (max-width: 1106px) and (min-width: 991.5px){
    .custom-nav .nav-link {
        font-size: 16px; /* Increased font size */
        margin-right: 10px; 
        font-weight: 500;
      }
    }
    .navbar-logo {
        width: 120px;
        height: 55px;
      }
      
      @media (max-width: 576px) {
        .navbar-logo {
          width: 90px;
          height: 40px;
        }
      }
      