@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
  font-family: 'Poppins', sans-serif;
}

.list-item span {
  display: inline-block;
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
}

.guideline-title {
  font-size: 3rem; /* Large screens */
  color: #333;
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
}

.guideline-subtitle {
  font-size: 2.1rem; /* Subtitle size */
  color: #ac834f; /* Subtitle color */
  margin-bottom: 1rem;
  text-align: center;
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
}

.guideline-text {
  font-size: 1.4rem; /* For smaller screens */
  color: #555;
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
  font-weight: 500;
}

.guideline-card {
  background-color: #f9f9f9; /* Light background for card */
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.list-item {
  margin-bottom: 15px; /* Space between list items */
}

.list-item span {
  position: relative;
  padding-left: 1.5em; /* Space for the bullet */
  color: black; /* Text color */
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
}

.list-item span::before {
  content: "•"; /* Bullet character */
  position: absolute;
  left: 0; /* Position it to the left */
  margin-top: -5px;
  color: #ac834f; /* Bullet color */
  font-size: 1.5em; /* Bullet size */
}

.download-button {
  background-color: #ac834f; /* Custom background color */
  color: white; /* Text color */
  padding: 12px 20px; /* Padding for the button */
  border-radius: 5px; /* Rounded corners */
  text-align: center; /* Centered text */
  display: inline-block; /* Inline-block for better spacing */
  transition: background-color 0.3s ease; /* Smooth transition */
  text-decoration: none; /* Remove underline */
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
}

.download-button:hover {
  background-color: #905e3b; /* Darker shade on hover */
}

.list-unstyled {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: 'Poppins', sans-serif; /* Ensure Poppins is used here */
}

@media (max-width: 768px) {
  .guideline-title {
    font-size: 2.5rem; /* Smaller screens */
  }

  .guideline-subtitle {
    font-size: 1.5rem; /* Smaller subtitle */
  }

  .download-button {
    display: flex; /* Use flexbox */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if needed */
  }

  .guideline-text {
    font-size: 1.2rem; /* Smaller text */
    text-align: start;
  }

  .my-5g {
    padding: 15px;
  }
}
