body {
    background-color: black; /* Black background */
    color: white; /* White text */
}

h6 {
    font-size: 3rem; /* Font size for h6 */
    color: white;
    font-weight: 700;
}

.card {
    background-color: #343a40; /* Dark card background */
    border: none; /* Remove border */
    padding: 20px;
    cursor: pointer;
}

.card-title {
    color: #ac834f; /* Gold color for card titles */
}

.containera {
    background-color: black;
    padding-bottom: 100px;
}

.card-img-top {
    align-self: center;
    transition: transform 0.3s ease; /* Add transition for smooth zoom effect */
}

.card-img-top:hover {
    transform: scale(1.1); /* Scale up the image on hover */
}

@media (max-width: 768px) {
    h6 {
        font-size: 2.5rem; /* Adjust font size for smaller screens */
    }
    
    .card {
        padding: 10px;
        margin-top: 20px;
    }
    
    .card-title {
        color: #ac834f; /* Maintain gold color */
        font-size: 1rem; /* Adjust size for responsiveness */
    }

    .card-img-top {
        width: 70%; /* Adjust image size */
    }

    .containera {
        padding: 5px;
    }
}

@media (min-width: 768.5px) and (max-width: 1200px) {
    .card {
        margin-left: -20px;
    }

    .card-title {
        font-size: 1rem; /* Consistent sizing */
    }

    .containera {
        padding: 15px;
    }
}
