.scroll-to-top-button {
    position: fixed;
    bottom: 20px; /* Adjust the distance from the bottom */
    right: 20px;  /* Adjust the distance from the right */
    
    
    border: none;
    border-radius: 50%;
    width: 40px; /* Size of the button */
    height: 40px;
    font-size: 24px; /* Size of the arrow */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 1000; /* Ensure it is above other content */
}


